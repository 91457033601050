<template>
  <div class="h-full max-w-mobile relative">
    <nav class="bg-white border-gray-200 bg-gray-900">
      <div
        class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4"
      >
        <div class="logo-container">
          <img
            class="logo-autores"
            src="@/assets/logo-512x512.png"
            alt="Logo Autores"
          />
          <div>
            <div class="text-md my-0 py-0 leading-4">Hernán Casciari</div>
            <div class="text-sm my-0 py-0 opacity-50">
              Autor, Productor, Artista
            </div>
          </div>
        </div>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span class="sr-only">Salir</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
            />
          </svg>
        </button>
      </div>
    </nav>

    <div class="h-full">
      <router-view />
    </div>
    <div
      class="fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-300 dark:bg-gray-700 dark:border-gray-600 pt-2"
    >
      <div class="grid h-full max-w-lg grid-cols-3 mx-auto font-normal">
        <router-link
          to="/reporte"
          type="button"
          class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5 text-gray-800 group-hover:text-blue-800 group-hover:font-bold"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819"
            />
          </svg>

          <span
            class="text-sm text-gray-800 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500 font-normal"
            >Inicio</span
          >
        </router-link>
        <router-link
          to="/reporte/transacciones"
          type="button"
          class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5 text-gray-800 group-hover:text-blue-800 group-hover:font-bold"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z"
            />
          </svg>

          <span
            class="text-sm text-gray-800 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500 font-normal"
            >Ventas</span
          >
        </router-link>
        <button
          type="button"
          class="inline-flex opacity-25 flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5 text-gray-800 group-hover:text-blue-800 group-hover:font-bold"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
            />
          </svg>

          <span
            class="text-sm text-gray-800 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500 font-normal"
            >Retiros</span
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.logo-autores {
  width: 2.7rem;
  margin-right: 0.5rem;
}
.logo-container {
  font-family: "Barlow", sans-serif;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
}
.max-w-mobile {
  max-width: 640px;
  /* Center */
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
</style>
